import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ServiceCall, Driver, Truck,
    GoaaaEnvironment
} from '@goaaa-mwg-tt/ionic-common';
import { Events } from '@ionic/angular';
import { AppDataService } from './app-data.service';

import * as firebase from 'firebase/app';
import { firestore } from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { SentryLoggingService } from './sentry-logging.service';
import { retryWithBackoff } from '../rxjs-operators/retry-with-backoff';

class FirebaseResource {
    descriptor: any;
    listenerUnsubscribe: any;
}

/**
 * Sets up Firebase listeners that are needed by the app.
 */
@Injectable({
    providedIn: 'root'
})
export class FirebaseDataService {

    private previousResources = new Map<string, FirebaseResource>();
    private previousOtwEnabled = null;
    private apps = new Map<string, firebase.app.App>();
    private firestore = null;
    private firebaseConfig = null;
    private callClosed = false;
    private callClosedStatuses = ['CA', 'CL', 'KI'];
    private memberAccessToken = null;
    private lastLocationUpdate: number = null;

    private listenerPostUnsubscribeFunctions = {
        driverDetails: () => {
            this.appData.driverDetails = null;
        },
        serviceCallDetails: () => {
            this.appData.serviceCallDetails = null;
        },
        truckDetails: () => {
            this.appData.truckDetails = null;
        },
        truckActivity: () => {
            console.log('Truck activity listener unsubscribed');
            this.appData.truckDataAvailable = false;
        },
        truckLocation: () => {
            this.appData.truckLocationWatchdogRef = null;
            this.appData.truckLocation = null;
        }
    };

    private listenerSetupFunctions = {
        driverDetails: (resource) => {
            // Make sure the data is in Firestore
            // expect(resource.descriptor.dbType).toEqual('firestore');

            console.log('Setting up driverDetails listener');

            // Set up the listener
            resource.listenerUnsubscribe = this.firestore.doc(resource.descriptor.path)
                .onSnapshot((snapshot) => {
                    if (snapshot.exists) {
                        // console.log(`Driver data changed: `, getUpdateDelta(snapshot.data(), resource.data));
                        resource.data = this.convertTimeStamps(snapshot.data());
                        this.appData.driverDetails = new Driver(resource.data);
                        console.log(`Driver data changed: `, resource.data);
                    } else {
                        console.log(`Driver does not exist at path: ${resource.descriptor.path}`);
                    }
                }, (error) => {
                    this.handleListenerError(error, resource.descriptor.path, { key: 'listener', content: 'driverDetails' });
                });
        },

        serviceCallDetails: (resource) => {
            // Make sure the data is in Firestore
            // expect(resource.descriptor.dbType).toEqual('firestore');

            console.log('Setting up serviceCallDetails listener');

            // Set up the listener
            resource.listenerUnsubscribe = this.firestore.doc(resource.descriptor.path)
                .onSnapshot((snapshot) => {
                    if (snapshot.exists) {
                        // console.log(`Driver data changed: `, getUpdateDelta(snapshot.data(), resource.data));
                        resource.data = this.convertTimeStamps(snapshot.data());
                        const sc = new ServiceCall(resource.data);
                        console.log(`Service call data changed: `, sc);

                        // Check for changes in the status
                        if (sc.currentStatus !== null) {
                            const previousCallClosed = this.callClosed;
                            this.callClosed = this.callClosedStatuses.includes(sc.currentStatus);
                            if (this.callClosed && !previousCallClosed) {
                                // this.log.message('Service call closed');
                                this.clearListeners();
                            }
                            if (this.appData.serviceCallDetails === null) {
                                // Notify subscribers of changes
                                this.events.publish('service-calls-status:updated', {
                                    previous: null,
                                    current: sc.currentStatus
                                });
                            } else if (this.appData.serviceCallDetails.currentStatus !== sc.currentStatus) {
                                // Notify subscribers of changes
                                this.events.publish('service-calls-status:updated', {
                                    previous: this.appData.serviceCallDetails.currentStatus,
                                    current: sc.currentStatus
                                });
                            }
                        }

                        // Update the information in the AppData service
                        this.appData.serviceCallDetails = sc;
                        this.appData.facilityId = sc.facilityId;
                        this.appData.truckId = sc.truckId;
                        this.appData.driverId = sc.driverId;
                    } else {
                        console.log(`Service call does not exist at path: ${resource.descriptor.path}`);
                    }
                }, (error) => {
                    this.handleListenerError(error, resource.descriptor.path, { key: 'listener', content: 'serviceCallDetails' });
                });
        },

        truckDetails: (resource) => {
            // Make sure the data is in Firestore
            // expect(resource.descriptor.dbType).toEqual('firestore');

            console.log('Setting up truckDetails listener');

            // Set up the listener
            resource.listenerUnsubscribe = this.firestore.doc(resource.descriptor.path)
                .onSnapshot((snapshot) => {
                    if (snapshot.exists) {
                        // console.log(`Driver data changed: `, getUpdateDelta(snapshot.data(), resource.data));
                        resource.data = this.convertTimeStamps(snapshot.data());
                        this.appData.truckDetails = new Truck(resource.data);
                        console.log(`Truck data changed: `, resource.data);
                    } else {
                        console.log(`Truck does not exist at path: ${resource.descriptor.path}`);
                    }
                }, (error) => {
                    this.handleListenerError(error, resource.descriptor.path, { key: 'listener', content: 'truckDetails' });
                });
        },

        truckActivity: async (resource) => {

            // Make sure the data is in the realtime database
            // expect(resource.descriptor.dbType).toEqual('realtime');

            console.log('Setting up truckActivity listener');

            // Get the reference
            const rtdb = await this.getRTDB(resource.descriptor.databaseURL);
            const ref = rtdb ? rtdb.ref(resource.descriptor.path) : null;

            // If we don't have a database reference, there's nothing to do
            if (!ref) {
                return;
            }

            console.log(`Creating truckActivity listener at path: ${ref.toString()}`);

            // Set up the listener
            const onValueChange = ref.on('value', (snapshot) => {
                if (snapshot.exists()) {
                    console.log('Truck is active');
                    this.appData.truckDataAvailable = true;
                } else {
                    console.log('Truck is inactive');
                    this.appData.truckDataAvailable = false;
                }
            }, (error) => {
                this.handleListenerError(error, resource.descriptor.path, { key: 'listener', content: 'truckActivity' });
            });

            // Set up the unsubscribe function
            resource.listenerUnsubscribe = () => {
                ref.off('value', onValueChange);
            };
        },

        truckLocation: async (resource) => {


            // Make sure the data is in the realtime database
            // expect(resource.descriptor.dbType).toEqual('realtime');

            console.log('Setting up truckLocation listener');

            // Get the reference
            // const rtdb = getRTDB(resource.descriptor.databaseURL);
            const rtdb = await this.getRTDB(resource.descriptor.databaseURL);
            const ref = rtdb ? rtdb.ref(resource.descriptor.path) : null;

            // If we don't have a database reference, there's nothing to do
            if (!ref) {
                return;
            }

            console.log(`Creating truckLocation listener at path: ${ref.toString()}`);


            // Clear any previous watchdog timer (clearInterval called in AppData)
            this.appData.truckLocationWatchdogRef = null;

            // Setup the location watchdog timer
            this.setupLocationWatchdog();

            // Set up the listener
            const onValueChange = ref.on('value', (snapshot) => {
                if (snapshot.exists()) {
                    // Indicate that we received a location update
                    this.lastLocationUpdate = Date.now();
                    // console.log(`truckLocation listener: lastLocationUpdate = ${this.lastLocationUpdate}`);

                    // console.log(`Truck location: ${JSON.stringify(snapshot.val())}`);
                    // Notify subscribers of changes
                    this.appData.truckLocation = snapshot.val();

                    // If we we're previously indicating that we can't connect to the truck, indicate that we are now getting data
                    if (!this.appData.truckDataAvailable) {
                        this.appData.truckDataAvailable = true;
                    }
                } else {
                    console.log(`Truck location does not exist at path: ${resource.descriptor.path}`);
                }
            }, (error) => {
                this.handleListenerError(error, resource.descriptor.path, { key: 'listener', content: 'truckLocation' });
            });

            // Set up the unsubscribe function
            resource.listenerUnsubscribe = () => {
                ref.off('value', onValueChange);
            };
        }
    };

    constructor(private http: HttpClient,
        private appData: AppDataService,
        private events: Events,
        private log: SentryLoggingService,
        private env: GoaaaEnvironment) {
        console.log('FirebaseAdapterProvider constructor');
        // Set up a subscription to the member access token so we can set up
        // Firebase as soon as it is available
        appData.memberAccessTokenObservable.subscribe((token) => {
            // As long as there is a non-null key, store it
            if (token) {
                console.log(
                    `Using member access token of '${token}' for getting service call details`);

                this.memberAccessToken = token;

                // Set up Firebase now that we have the member access token
                this.setupFirebase(token);
            }
        });
    }

    private handleListenerError(error, path, extra) {
        // If we know the call is already closed, don't do anything
        if (!this.callClosed) {
            console.error(`Listener error on ${path}: ${error.message}`);
            // this.log.error(error, extra);

            // Restart the connection to Firebase and the listeners.
            // If the call is closed, we will then automatically display the correct error message to the member
            if (!(this.appData[extra.content] === null || Object.keys(this.appData[extra.content]).length === 0)) {
                // Only attempt a full reconnection to firebase if listener errors occur after there was an initial
                // first connection where we got data for the specific resource (not null or empty object)
                this.clearListeners();
                this.setupFirebase(this.memberAccessToken);
            }
        }
    }

    private setupFirebase(token: string): void {
        this.callClosed = false;
        // Make a call to the backend API to get the associated service call details
        //  from the member access token
        const url = `${this.env.endpoints.realtime}/service-calls?appId=${this.env.appName}&memberAccessToken=${token}`;
        this.http.get<any>(url).pipe(
            retryWithBackoff(1000, 2, 1000, (error) => {
                if (error.hasOwnProperty('status') && [404, 409].includes(error.status)) {
                    return false; // Don't retry if status is 409 (call is closed)
                }
                return true;
            })
        )
            .subscribe((config) => {

                console.log(config);

                try {
                    const pathParts = (config.resourcesPath as String).split('/');
                    this.log.serviceCallId = pathParts[pathParts.length - 1];
                    // Initialize firebase
                    let app = this.apps.get('[DEFAULT]');
                    if (app == null) {
                        app = firebase.initializeApp(config.dbConfig);
                        this.apps.set('[DEFAULT]', app);
                    }
                    this.firebaseConfig = config;

                    // Authenticate using the provided token
                    firebase.auth().signInWithCustomToken(config.token)
                        .then((user) => {
                            // this.log.message('Authentication successful', { key: 'user', content: user.user.uid });
                            console.log(user.user.uid);
                            // Provide storage access to the app data service
                            this.appData.setFirebaseStorage(firebase.storage());

                            // Create a listener on the resources document
                            this.firestore = firebase.firestore();
                            this.firestore.doc(config.resourcesPath).onSnapshot(async (snapshot) => {
                                if (snapshot.exists) {
                                    const resources = snapshot.data();
                                    console.log(resources);
                                    if (resources.hasOwnProperty('otwEnabled')) {
                                        // If OTW is not enabled, clear out the truck and driver resources
                                        if (!resources.otwEnabled) {
                                            resources.truckDetails = null;
                                            resources.truckLocation = null;
                                            resources.truckActivity = null;
                                            resources.driverDetails = null;
                                            // this.appData.truckDataAvailable = false;
                                        }
                                        this.appData.otwEnabled = resources.otwEnabled;
                                    }
                                    for (const resourceName in resources) {
                                        if (resources.hasOwnProperty(resourceName) &&
                                            Object.keys(this.listenerSetupFunctions).includes(resourceName)) {
                                            this.checkResourceUpdates(resourceName, resources[resourceName]);
                                        }
                                    }
                                    console.log('setupFirebase() succeeded');
                                } else {
                                    console.log(`Resources document does not exist at path: ${config.resourcesPath}`);
                                    // throw { status: 404 };
                                }
                            }, (error) => {
                                this.handleListenerError(error, config.resourcesPath, { key: 'listener', content: 'serviceCallResources' });
                            });
                        });
                } catch (error) {
                    console.error('Could not set up firebase listeners: ', error);
                }
            });
    }

    private convertTimeStamps(object: any): any {
        for (const field in object) {
            if (object[field] instanceof Object) {
                if (object[field] instanceof firestore.Timestamp) {
                    const stamp: firestore.Timestamp = object[field];
                    object[field] = stamp.toDate().toISOString();
                } else {
                    // Call recursively to handle nested objects
                    this.convertTimeStamps(object[field]);
                }
            }
        }
        return object;
    }

    checkResourceUpdates(resourceName, resourceDescriptor) {
        const resource = this.previousResources.get(resourceName);
        const previousResource = resource ? JSON.parse(JSON.stringify(resource)) : {};
        let resourceChanged = false;
        if (resource) {
            // If the resource descriptors don't match, the resource has changed and the listeners need to be updated
            if (!resource.descriptor || JSON.stringify(resource.descriptor) !== JSON.stringify(resourceDescriptor)) {
                resourceChanged = true;

                // Store the new descriptor
                resource.descriptor = resourceDescriptor;

                // If a previous listener exists, unsubscribe first
                if (resource.listenerUnsubscribe) {
                    resource.listenerUnsubscribe();
                    resource.listenerUnsubscribe = null;
                    if (this.listenerPostUnsubscribeFunctions.hasOwnProperty(resourceName)) {
                        this.listenerPostUnsubscribeFunctions[resourceName]();
                    }
                }

                // Set up the new listener
                if (resource.descriptor && resource.descriptor.path) {
                    this.listenerSetupFunctions[resourceName](resource);
                }
            }
        } else {
            // This is the first time setting up the listener, so create the resource object as well
            if (resourceDescriptor) {
                resourceChanged = true;
                this.previousResources.set(resourceName, {
                    descriptor: resourceDescriptor,
                    listenerUnsubscribe: null
                });
                this.listenerSetupFunctions[resourceName](this.previousResources.get(resourceName));
            }
        }

        if (resourceChanged) {
            // this.log.message('Service call resource changed', {
            //     key: 'details', content:
            //         { resourceName: resourceName, previous: previousResource.descriptor, current: resourceDescriptor }
            // });
        }
    }

    getRTDB(url): Promise<void | firebase.database.Database> {
        let rtdbApp = this.apps.get(url);
        if (!rtdbApp) {
            rtdbApp = firebase.initializeApp(Object.assign({}, this.firebaseConfig.dbConfig, { databaseURL: url }), url);
            this.apps.set(url, rtdbApp);
        }
        const auth = firebase.auth(rtdbApp);
        if (auth.currentUser !== null) {
            return Promise.resolve(firebase.database(rtdbApp));
        }
        return firebase.auth(rtdbApp).signInWithCustomToken(this.firebaseConfig.token)
            .then(() => {
                return Promise.resolve(firebase.database(rtdbApp));
            })
            .catch((err) => {
                console.error(err);
            });
    }

    public setupLocationWatchdog(): void {
        // Set up the truck location watchdog timer
        this.lastLocationUpdate = null;
        const watchdogTimeoutMs = 15000;
        this.appData.truckLocationWatchdogRef = window.setInterval(() => {
            // console.log(`truckLocation watchdog: lastLocationUpdate = ${this.lastLocationUpdate}`);
            if (this.lastLocationUpdate && (Date.now() - this.lastLocationUpdate) > watchdogTimeoutMs) {
                console.log(`Location watchdog timeout (${watchdogTimeoutMs / 1000} seconds)`);
                // Show can't connect to truck message
                this.appData.truckDataAvailable = false;

                // Log an error to Sentry
                // this.log.message('Truck location timeout',
                //     { key: 'details', content: { truckLocation: this.appData.truckLocation } }, 'error');

                // Reset the watchdog so we don't generate more error messages unless it times out again
                this.lastLocationUpdate = Date.now();
            }
        }, watchdogTimeoutMs);
    }

    public clearListeners(): void {
        this.previousResources.forEach((value, key, map) => {
            // Call stored unsubscribe function
            if (value && value.listenerUnsubscribe) {
                value.listenerUnsubscribe();
                value.listenerUnsubscribe = null;
            }
        });
    }
}
